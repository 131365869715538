@import "../assets/sass/globals.scss";

.section {
    text-align: left;
    padding: 0 3.6rem;
}

.section__headline {
    padding: 0 1.2rem;
    margin: 0;
    margin-top: 2.4rem;
}

.section__content {
    p {
        padding: 0 1.2rem 2.4rem;
    }
}

.section__content-teaser-grid {
    display: flex;
    flex-wrap: wrap;
}

// -------- Breakpoints

@media ($bp-larger-than-desktophd) {
    .section__content-teaser-grid {
        .person-teaser {
            width: calc(100% / 8);
        }

        .movie-teaser {
            width: calc(100% / 8);
        }
    }
}

@media ($bp-larger-than-desktop) {
    .section__content-teaser-grid {
        .person-teaser {
            width: calc(100% / 8);
        }
        .movie-teaser {
            width: calc(100% / 6);
        }
    }
}

@media ($bp-larger-than-tablet) {
    .section__content-teaser-grid {
        .movie-teaser {
            width: calc(100% / 6);
        }
    }
}

@media ($bp-larger-than-mobile) {
    .section {
        padding: 0 1.2rem;
    }
    .section__content-teaser-grid {

        .person-teaser {
            width: calc(100% / 2);
        }
        .movie-teaser {
            width: calc(100% / 3);
        }
    }
}
