@import "./assets/sass/globals.scss";

// -------- Global DOM settings
html {
  font-size: $font-size;
}

body {
  font-size: 1.2rem;
  line-height: 2.6rem;
  @media ($bp-larger-than-desktophd) {
    font-size: 2.4rem;
    line-height: 3.6rem;
  }
  @media ($bp-larger-than-desktop) {
    font-size: 1.6rem;
    line-height: 2.6rem;
  }
  @media ($bp-larger-than-tablet) {
    font-size: 1.2rem;
    line-height: 2.2rem;
  }
  color: $font-color;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: radial-gradient(farthest-corner at 40px 40px, rgba(36, 24, 24, 1) 0%, rgba(13, 9, 8, 1) 100%);
  color: white;
  text-align: center;
  overflow-x: hidden;
  margin: 0;
}

// -------- Headlines
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Loewblack";
}

.headline--sm {
  font-size: 2.4rem;
  text-transform: uppercase;
}

// -------- Helper
.hide {
  display: none;
}

.mobileOff {
  display: none;

  @media ($bp-larger-than-tablet) {
    display: block;
  }
}

.mobileOn {
  display: block;

  @media ($bp-larger-than-tablet) {
    display: none;
  }
}
.app-version {
  color: rgba(255, 255, 255, 0.3);
  margin: 1rem;
}

// -------- headlines

// -------- Figures and Image defaults
figure {
  margin: 0;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  color: white;
}
