.movie-teaser {
    width: calc(100% / 6);
    margin-bottom: 24px;

    &.onMyList {
        border-top: 1px solid red;
    }

    figure {
        all: unset;
        display: block;
        margin: 12px;

        img {
            width: 100%;
            border-radius: .6rem;
        }

        figcaption {
            font-size: 1rem;
            margin-left: 1.2rem;
            height: 2.4rem;
            font-weight: 200;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}

.actor__name {}

.actor__character {
    color: #BC4444;
}
