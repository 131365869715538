.heart__circle {
    transition: all 350ms ease-in-out;
    cursor: pointer;
    border-radius: 100%;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.on-img {
        position: absolute;
        top: 6px;
        right: 6px;
    }

    &.active {
        border: 2px solid rgb(230, 10, 58);

        &:hover {
            background: rgba(230, 10, 58, .12);
        }
    }

    &.inactive {
        border: 2px solid rgb(221, 221, 221);

        &:hover {
            background: rgba(221, 221, 221, .12);
        }
    }

    &.shadow {
        background: rgba(0, 0, 0, 0.48);
    }
}

.heart__circle--default {
    @extend .heart__circle;
    transform: scale(1);
}

.heart__circle--small {
    @extend .heart__circle;
    transform: scale(0.45);
}
