.notification {
    box-sizing: bord;
    width: fit-content;
    padding: 12px;
    margin: 24px 0;
    align-self: center;
}

.notification__alert {
    background: rgba(172, 44, 44, 0.12);
    border: 1px solid rgba(255, 0, 0, .24)
}