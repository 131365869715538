.load-more {
    all: unset;
    cursor: pointer;

    span {
        font-size: 1.2rem;
        text-transform: uppercase;
        display: block;
        margin-bottom: -6px;
    }
}
