.rating {
    display: flex;
    flex-direction: column;
    align-items: baseline;

    .primary {
        font-family: "Loewblack";
        font-size: 2.4rem;
    }

    .label {
        font-family: "Loewlight";
        font-size: 1.2rem;
    }
}