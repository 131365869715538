@import "../../assets/sass/globals.scss";

.input__search {
    background: none;
    width: calc(100% - 120px);
    border: 1px solid rgba(255,255,255, .24);
    border-radius: .55rem;
    padding: 12px;
    margin: 48px;
    color: white;
    text-transform: lowercase;
}

.list__container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
}



// -------- Breakpoints

@media($bp-larger-than-desktophd) {
    .list__container {
        .movie-teaser {
            width: calc(100% / 8)
        }
    }
}

@media($bp-larger-than-desktop) {
    .list__container {
        .movie-teaser {
            width: calc(100% / 6)
        }
    }
}

@media($bp-larger-than-tablet) {
    .list__container {
        .movie-teaser {
            width: calc(100% / 4)
        }
    }
}

@media($bp-larger-than-mobile) {
    .list__container {
        .movie-teaser {
            width: calc(100% / 2)
        }
    }
}