@import "../../assets/sass/globals.scss";

.page__movie {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header__left,
.header__right {
    padding-top: 6rem;
    width: calc(50% - 4.8rem);
    height: calc(100% - 6rem);
}

.header__left {
    display: flex;
    justify-content: left;
    flex-direction: column;
    background: linear-gradient(-90deg, rgba(0, 0, 0, 0) 0%, black 100%);
    padding-left: 4.8rem;

    .movie__short-description {
        width: 100%;
        margin: 0;

        .movie__title--big {
            h1 {
                font-size: 5.4rem;
                line-height: 5.4rem;
                margin-bottom: 1.2rem;
            }
            h2 {}
        }

        .movie__description {
            width: 72%;
        }
    }
}

.header__right {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 2.4rem;

    .movie__detail-poster {
        border: 4px solid #f2f2f2;
        line-height: 0;
        width: 42%;
        margin-top: -4.8rem;

        img {
            width: 100%;
        }
    }
}

.header__bottom {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, black 100%);
    position: absolute;
    bottom: 4.8rem;
    width: calc(100% - 9.6rem);
    padding: 2.4rem 4.8rem;
    bottom: 0;
    display: flex;
    justify-content: space-between;
}

.info__container {
    display: flex;
}

.btn__back {
    all: unset;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    margin-left: -1.2rem;

    &:hover > .back__title {
        transition: all 250ms ease-in-out;
        margin-left: 0.6rem;
    }

    .back__title {
        transition: all 250ms ease-in-out;
        margin-left: 0;

        span {
            display: block;
            text-align: left;
        }

        .title {
            font-family: "Loewblack";
            text-transform: uppercase;
            font-size: 1.6rem;
        }

        .back {
            font-size: 1.2rem;
            line-height: 1.2rem;
            font-weight: 200;
        }
    }
}

.btn {
    all: unset;
    background: white;
    color: black;
    text-align: center;
    border-radius: 15px;
}

.btn__circle {
    cursor: pointer;

    .btn__ghost--imdb {
        text-decoration: none;
        font-family: "Impact";
        color: black;
        background: rgb(245, 197, 24);
        border-radius: 100%;
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.main__content {}

.movie__short-description {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 24px;

    h1 {
        font-size: 3.2rem;
        line-height: 4.2rem;
        margin: 0;
        text-transform: uppercase;
    }

    h2 {
        font-family: "Loewlight";
        text-transform: uppercase;
        font-size: 1.6rem;
        margin: 0;
        font-weight: 200;
    }

    p {
        margin: 0 0 6px 0;
    }
}

.text__icon {
    display: inline-flex;
    align-items: center;
    font-size: 1.2rem;
    text-decoration: none;
}

.short-info {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.btn__group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .btn,
    .heart__circle--default {
        margin: 1.2rem 1.2rem 1.2rem 0rem;
    }

    .btn__ghost {
        justify-content: center;
        width: 4.8rem;
        height: 4.8rem;
        border-radius: 4.8rem;
        border: 1px solid rgb(245, 245, 245);
        transition: all 350ms ease-in-out;
        cursor: pointer;
        display: flex;
        align-items: center;
        background: none;
        color: rgb(245, 245, 245);

        &:hover {
            background: rgba(245, 245, 245, 0.12);
        }
    }
}

.movie__detail-header {
    display: flex;
}

.movie__detail-poster {
    width: 25%;
}


@keyframes shrink {
  0% {
    background-size: 102%;
  }
  100% {
    background-size: 100%;
  }
}


// -------- Breakpoints

@media($bp-larger-than-desktophd) {}

@media($bp-larger-than-desktop) {}

@media($bp-larger-than-tablet) {}