.form__container {
    padding: 70px;
    width: calc(100% - 140px);

    form {
        display: flex;
        flex-direction: column;

        input {
            all: unset;
            margin-bottom: 24px;
            border: 1px solid #bfbfbf;
            font-size: 1.2rem;
            border-radius: 4px;
            padding: 12px;
            color: black;
            border: 1px solid rgba(0, 0, 0, 0.24);
            text-align: left;
        }

        .button__signUp,
        button {
            all: unset;
            cursor: pointer;
            margin-bottom: 24px;
            margin-top: 24px;
            padding: 12px;
            border: none;
            border-radius: 4px;
            color: white;
            background: rgb(230, 10, 58);
            box-shadow: 0px 12px 24px rgba(230, 10, 58, 0.44);
            font-size: 1.6rem;
        }

        .form__caption {
            a {
                font-size: 1.2rem;
                color: black;
            }
        }
    }
}
