.person-teaser {
    &.size-default {
    }

    &.size-small {
        width: calc(100% / 8);
    }

    figure {
        all: unset;
        display: block;
        margin: 12px;
        
        img {
            width: 100%;
            border-radius: .6rem;
        }

        figcaption {
            font-size: 1rem;
            margin-left: 1.2rem;
            height: 2.4rem;
            font-weight: 200;
            flex-direction: row;
            justify-content: left;
            flex-wrap: wrap;
            display: flex;
            align-items: center;
            justify-content: space-between;

            span {
                width: 100%;
                line-height: 1.8rem;
            }
        }
    }
}

.actor__name {
}

.actor__character {
    color: #bc4444;
}
