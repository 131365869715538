.page {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    transition: all 500ms ease-in-out;
    min-height: 100vh;
    max-height: 100%;
}

.page__inner {
    width: 91vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.page__movie {
    .page__inner {
        width: 100vw;
    }
}

.page__search,
.page__our-list {
    padding: 6rem 0 0 0;
}