.navbar__user-nav {
    all: unset;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    li {
        list-style: none;
        
        button {
            all: unset;
            cursor: pointer;
            border: 2px solid rgba(255, 255, 255, 0.24);
            border-radius: 83px;
            padding: 0px 0px 0px 13px;
            display: flex;
            align-items: center;
            font-size: 1.2rem;

            .icon {
                margin-top: -3px;
            }
        }

        .user-nav__subnav {
            position: absolute;
            right: 0;
            margin-top: 6px;
            display: block;
            background: black;
            padding: 0;
            transition: all 100ms ease-in-out;

            &.active {
                opacity: 1;
                margin-top: 6px;
            }

            &.inactive {
                opacity: 0;
                margin-top: 0;
            }

            button {
                all: unset;
                cursor: pointer;
                padding: 12px 24px;
                text-align: center;
            }
        }
    }
}