@import "../../assets/sass/globals.scss";

@keyframes shrink {
    0% {
        background-size: 102%;
    }
    100% {
        background-size: 100%;
    }
}

.page__login {
    height: calc(100vh - 100px);
    align-items: center;

    .login__container {
        width: 100vw;
        height: 100vh;
        background: url("/assets/images/collection.jpg") center no-repeat;
        animation: shrink 10s infinite alternate;
        background-size: cover;

        .login__container--blend {
            width: 100vw;
            height: 100vh;
            background: rgba(0, 0, 0, 0.53);
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .login__form {
            width: 23%;
            height: 58vh;
            padding-top: 4.8rem;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            background: whitesmoke;
            color: black;
            box-shadow: 0px 24px 48px rgba(0, 0, 0, 0.8);

            .app__logo--round {
                width: 167px;
                height: 167px;
                background: black;
                border-radius: 50%;
                color: #f2f2f2;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                .title {
                    font-family: "Loewblack";
                    font-size: 2.2rem;
                    text-transform: uppercase;
                }
                .label {
                    font-family: "Loewlight";
                    font-size: 1.2rem;
                    line-height: 1.6rem;
                }
            }

            h1 {
                margin: 0;
                padding: 0;
                font-weight: 200;
            }
        }
    }
}

// -------- Breakpoints

@media ($bp-larger-than-desktophd) {
    .page__login {
        .login__container {
            .login__form {
                width: 33%;
                height: 81vh;
            }
        }
    }
}

@media ($bp-larger-than-desktop) {
}

@media ($bp-larger-than-tablet) {
    .page__login {
        .login__container {
            animation: none;

            .login__form {
                width: 33%;
                height: 81vh;
            }
        }
    }
}

@media ($bp-larger-than-mobile) {
    .page__login {
        .login__container {
            animation: none;
            background-repeat: repeat;

            .login__form {
                width: 100%;
                height: 68vh;
            }
        }
    }
}
