.page-container__header {
  width: 100vw;
  height: 50px;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;

  a {
    text-decoration: none;
  }
}

.header__inner {
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.app__logo {
  font-family: "Loewlight";
  text-transform: lowercase;
  font-weight: bold;
  padding: 0 24px;
  height: 4.8rem;
  display: flex;
  align-items: center;

  span {
    &:first-of-type {}
    &:last-of-type {}
  }
}

.page-container__navbar {
  display: flex;
  background: black;
  border-radius: 0px 0px 0px 24px;
  height: 4.8rem;
  padding-right: 2.4rem;
}

.navbar {
  padding: 0 2.4rem;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .navbar__item {
    display: inline-flex;
    margin: 0 6px;

    a {
      display: inline-flex;
      transition: 500ms all ease-in-out;
      transform: scale(1);
      filter: blur(0px);

      &.active {
        transition: 500ms all ease-in-out;
        opacity: 0.75;
        transform: scale(0.8);
        filter: blur(2px);
      }
    }

    button {
    }
  }
}
